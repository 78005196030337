import { Page, Part } from '../database/page';
import { component, html } from './deps';
import post from './post';

export default class AdminPage extends component {
    static tag = 'x-admin-page';

    status: 'cloud_done' | 'cloud_off' = 'cloud_done';
    page: Page = { id: '', path: '', title: '', description: '', parts: [], };

    addPart() {
        this.page.parts.push({ name: '' });
    }

    removePart(part: Part) {
        if (!confirm(`Are you sure you want to Remove this Part?`)) return;
        this.page.parts.splice(this.page.parts.indexOf(part), 1);
    }

    // removeField(section: Section, index: number) {
    //     if (!confirm(`Are you sure you want to Remove "${section.fields[index].name}" Field?`)) return;
    //     section.fields.splice(index, 1);
    // }

    // addField(section: Section) {
    //     section.fields.push({
    //         name: '',
    //         amount: 0,
    //         type: 'text',
    //         payment: false,
    //         required: true,
    //     });
    // }

    async pageRemove() {
        if (!confirm(`Are you sure you want to Remove this page?`)) return;
        const { id } = this.page;
        await post('/admin/page/remove', { id });
        location.replace('/admin');
    }

    async input() {
        this.status = 'cloud_off';
    }

    async submit(e) {
        e.preventDefault();
        if (this.page.id) {
            await post('/admin/page/update', this.page);
        } else {
            const page = await post<Page>('/admin/page/create', this.page);
            location.replace(`${location.pathname}?id=${page.id}`);
        }
        this.status = 'cloud_done';
    }

    async connected() {
        const id = new URL(location.href).searchParams.get('id');

        if (id) {
            this.page = await post('/admin/page/view', { id });
        }

        console.log(this.page);
    }

    render = () => html`
    <div class="container">

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Admin Page Manager</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white"></p>
            </div>
        </div>

        <div class="flex flex-row">
            <div class="font-size-xl margin-xs">
                <a href="/admin" class="button button-border button-primary">
                    <span class="material-symbols-outlined">dashboard</span>
                    <span>Dashboard</span>
                </a>
            </div>
        </div>

        <div class="flex flex-row justify-content-flex-end padding-xs">
            <div class="font-size-xl">
                <div class=${this.status === 'cloud_done' ? 'material-symbols-outlined color-success' : 'material-symbols-outlined color-grey'} >
                    ${this.status}
                </div>
            </div>
        </div>

        <form class="border padding-s" onsubmit=${this.submit} oninput=${this.input}>

            <div class="flex flex-row flex-wrap">
                <label class="field">
                    <div>Page Title</div>
                    <input type="text" placeholder="Page Title" value=${this.page.title} oninput=${e => this.page.title = e.target.value} required/>
                </label>
                <label class="field">
                    <div>Page Path</div>
                    <input type="text" placeholder="Page Path" value=${this.page.path} oninput=${e => this.page.path = e.target.value} required/>
                </label>
            </div>

            <div class="flex flex-column">
                ${this.page.parts.map(part => html`
                    ${part}
                `)}

                <div class="flex flex-row">
                    <button type="button" class="button button-border button-success margin-xxs" onclick=${this.addPart}>
                        <span class="material-symbols-outlined">add</span>
                        <span>Add Part</span>
                    </button>
                </div>

                <div class="flex flex-row flex-wrap justify-content-flex-end">
                    <button type="submit" class="button button-primary margin-xxs">
                        <span class="material-symbols-outlined">save</span>
                        <span>${this.page.id ? 'Update' : 'Create'} Page</span>
                    </button>
                    ${this.page.id ? html`
                    <button type="button" class="button button-error margin-xxs" onclick=${this.pageRemove}>
                        <span class="material-symbols-outlined">delete</span>
                        <span>Remove Page</span>
                    </button>
                    ` : ''}
                </div>
            </div>
        </form>

    </div>
    `

}
