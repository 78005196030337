import { Form } from '../database/form.ts';
import { Item } from '../database/result.ts';

export default function (trustedForm: Form, untrustedForm: Form) {

    let amount = 0;
    const items: Item[] = [];
    const l = trustedForm.sections.length;

    for (let i = 0; i < l; i++) {
        const trustedSection = trustedForm.sections[i]
        const untrustedSection = untrustedForm.sections[i];

        let ll = trustedSection.fields.length;
        for (let ii = 0; ii < ll; ii++) {

            const trustedField = trustedSection.fields[ ii ];
            const untrustedField = untrustedSection.fields[ ii ];

            // field is marked as payment and has a value increase amount
            if (trustedField.type === 'number' && trustedField.multiplier) {

                amount += (trustedForm.amount * untrustedField.value);

            } else if (trustedField.type === 'select' && trustedField.options?.length) {

                // find selected options and increase by selected amount
                (untrustedField.value as string)?.split(', ').forEach(v => {
                    trustedField.options?.forEach(o => {
                        if (o.payment && o.value === v && v) {
                            amount += o.amount;
                        }
                    });
                });

            } else {

                if (trustedField.payment && untrustedField.value) {
                    amount += trustedField.amount;
                }

            }

            items.push({ name: trustedField.name, value: untrustedField.value, required: trustedField.required });
        }
    }

    return { items, amount };
}