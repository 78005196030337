import { Result } from '../database/result';
import { component, html } from './deps';
import post from './post';
import csv from './csv';

export default class AdminForm extends component {
    static tag = 'x-admin-form';

    id: string = '';
    results: Result[] = [];

    download () {
        const data = csv(this.results);
        const blob = new Blob([data], {type: 'text/csv'});
        location.href = URL.createObjectURL(blob);
    }

    async connected() {
        const id = new URL(location.href).searchParams.get('id');
        this.id = id ?? '';
        if (id) this.results = await post('/admin/result/search', { form: id });
    }

    render = () => html`
    <div class="container">

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Admin Result</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white"></p>
            </div>
        </div>

        <div class="flex flex-row">
            <div class="font-size-xl margin-xs">
                <a href=${'/admin/form?id=' + this.id} class="button button-border button-primary">
                    <span class="material-symbols-outlined">arrow_back</span>
                </a>
            </div>
            <div class="font-size-xl margin-xs">
                <button class="button button-border button-primary" onclick=${this.download}>
                    <span class="material-symbols-outlined">download</span>
                </button>
            </div>
        </div>

        ${this.results.map(result => html`
        <details>
            <summary>${result.email} ${new Date(result.created).toLocaleString()}</summary>
            <div class="table">
                <div class="font-weight-bold">
                    <span>Name</span>
                    <span>Value</span>
                </div>
                <div>
                    <span>Created</span>
                    <span>${new Date(result.created).toLocaleString()}</span>
                </div>
                <div>
                    <span>Name</span>
                    <span>${result.name}</span>
                </div>
                <div>
                    <span>Phone</span>
                    <span>${result.phone}</span>
                </div>
                <div>
                    <span>Email</span>
                    <span>${result.email}</span>
                </div>
                ${result.charge ? html`
                <div>
                    <span>Charge</span>
                    <span>${result.charge}</span>
                </div>
                ` : '' }
                ${result.amount ? html`
                <div>
                    <span>Amount</span>
                    <span>$${result.amount?.toLocaleString()}</span>
                </div>
                ` : '' }
                ${result.items.map(item => html`
                <div>
                    <span>${item.name}</span>
                    <span>${item.value}</span>
                </div>
                `)}
            </div>
        </details>
        `)}

    </div>
    `

}
