import { Form, Section } from '../database/form';
import { component, html } from './deps';
import post from './post';

export default class AdminForm extends component {
    static tag = 'x-admin-form';

    status: 'cloud_done' | 'cloud_off' = 'cloud_done';

    form: Form = { id: '', name: '', path: '', payment: false, amount: 0, created: 0, updated: 0, sections: [] };

    addSection() {
        this.form.sections.push({ name: '', fields: [] });
    }

    removeSection(section: Section) {
        if (!confirm(`Are you sure you want to Remove "${section.name}" Section?`)) return;
        this.form.sections.splice(this.form.sections.indexOf(section), 1);
    }

    removeField(section: Section, index: number) {
        if (!confirm(`Are you sure you want to Remove "${section.fields[index].name}" Field?`)) return;
        section.fields.splice(index, 1);
    }

    addField(section: Section) {
        section.fields.push({
            name: '',
            amount: 0,
            type: 'text',
            payment: false,
            required: true,
        });
    }

    async formRemove() {
        if (!confirm(`Are you sure you want to Remove "${this.form.name}" Form?`)) return;
        const { id } = this.form;
        await post('/admin/form/remove', { id });
        location.replace('/admin');
    }

    async input() {
        this.status = 'cloud_off';
    }

    async submit(e) {
        e.preventDefault();
        if (this.form.id) {
            await post('/admin/form/update', this.form);
        } else {
            const form = await post<Form>('/admin/form/create', this.form);
            location.replace(`${location.pathname}?id=${form.id}`);
        }
        this.status = 'cloud_done';
    }

    async connected() {
        const id = new URL(location.href).searchParams.get('id');

        if (id) {
            this.form = await post('/admin/form/view', { id });
        }

        console.log(this.form);
    }

    render = () => html`
    <div class="container">

        <div class="container padding-m background-primary">
            <div class="margin-vertical-l">
                <h1 class="display-title color-white">Admin Form Manager</h1>
                <hr class="divider background-white">
                <p class="lead lead-s margin-bottom-m color-white"></p>
            </div>
        </div>

        <div class="flex flex-row">
            <div class="font-size-xl margin-xs">
                <a href="/admin" class="button button-border button-primary">
                    <span class="material-symbols-outlined">dashboard</span>
                    <span>Dashboard</span>
                </a>
            </div>
            <div class="font-size-xl margin-xs">
                <a href=${'/admin/result?id=' + this.form.id} class="button button-border button-primary">
                    <span class="material-symbols-outlined">database</span>
                    <span>Result</span>
                </a>
            </div>
            <div class="font-size-xl margin-xs">
                <a href=${'/admin/preview?id=' + this.form.id} class="button button-border button-primary">
                    <span class="material-symbols-outlined">preview</span>
                    <span>Preview</span>
                </a>
            </div>
        </div>

        <div class="flex flex-row justify-content-flex-end padding-xs">
            <div class="font-size-xl">
                <div class=${this.status === 'cloud_done' ? 'material-symbols-outlined color-success' : 'material-symbols-outlined color-grey'} >
                    ${this.status}
                </div>
            </div>
        </div>

        <form class="border padding-s" onsubmit=${this.submit} oninput=${this.input}>

            <div class="flex flex-row flex-wrap">
                <label class="field">
                    <div>Form Name</div>
                    <input type="text" placeholder="Form Name" value=${this.form.name} oninput=${e => this.form.name = e.target.value} required/>
                </label>
                <label class="field">
                    <div>Form Path</div>
                    <select class="select" name="Form Path" value=${this.form.path} oninput=${e => this.form.path = e.target.value} required>
                        <option value="">None</option>
                        <option value="/donate">/donate</option>
                        <option value="/registration/bio">/registration/bio</option>
                        <option value="/registration/gala">/registration/gala</option>
                        <option value="/registration/poster">/registration/poster</option>
                        <option value="/registration/vendor">/registration/vendor</option>
                        <option value="/registration/speaker">/registration/speaker</option>
                        <option value="/registration/conference">/registration/conference</option>
                        <option value="/nominate/top-5">/nominate/top-5</option>
                        <option value="/nominate/fabulous-50">/nominate/fabulous-50</option>
                        <option value="/nominate/alex-stuetze">/nominate/alex-stuetze</option>
                        <option value="/nominate/certifications-grant">/nominate/certifications-grant</option>
                        <option value="/nominate/inspirational-mentor">/nominate/inspirational-mentor</option>
                    </select>
                </label>
                <label class="field">
                    <div>Form Payment</div>
                    <input type="checkbox" ${this.form.payment ? 'checked' : ''} oninput=${e => this.form.payment = e.target.checked}/>
                </label>
                ${this.form.payment ? html`
                <label class="field">
                    <div>Form Payment Amount</div>
                    <input type="number" min="0" placeholder="Form Payment Amount" value=${this.form.amount} oninput=${e => this.form.amount = e.target.valueAsNumber} required/>
                </label>
                `: ((this.form.amount = 0) ? '' : '')}
            </div>

            <div class="flex flex-column">
                ${this.form.sections.map(section => html`
                <div class="flex flex-column border padding-xs">

                    <div class="flex flex-row flex-wrap">
                        <div class="field">
                            <label for="Section Name">Section Name</label>
                            <input type="text" name="Section Name" placeholder="Section Name" value=${section.name} oninput=${e => section.name = e.target.value} required/>
                        </div>
                        <div class="flex align-items-flex-end">
                            <button type="button" class="button button-border button-error margin-xxs" onclick=${() => this.removeSection(section)}>
                                <span class="material-symbols-outlined">delete</span>
                                <span>Remove Section</span>
                            </button>
                        </div>
                    </div>

                    ${section.fields.map((field, index) => html`
                    <div class="flex flex-column border-bottom">

                        <div class="flex flex-row flex-wrap">
                            <label class="field">
                                <div>Field Type</div>
                                <select name="Field Type" value=${field.type} oninput=${e => field.type = e.target.value} required>
                                    <option value="text">Text</option>
                                    <option value="number">Number</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="textarea">Textarea</option>
                                    <option value="select">Select</option>
                                </select>
                            </label>
                            <label class="field">
                                <div>Field Name</div>
                                <input type="text" placeholder="Name" value=${field.name} oninput=${e => field.name = e.target.value} required/>
                            </label>
                            <label class="field">
                                <div>Field Required</div>
                                <input type="checkbox" ${field.required ? 'checked' : ''} oninput=${e => field.required = e.target.checked} />
                            </label>
                            ${field.type === 'select' ? html`
                                <label class="field">
                                    <div>Field Multiple</div>
                                    <input type="checkbox" ${field.multiple ? 'checked' : ''} oninput=${e => field.multiple = e.target.checked} />
                                </label>
                            ` : ''}
                            ${this.form.payment && field.type === 'checkbox' ? html`
                                <label class="field">
                                    <div>Field Payment</div>
                                    <input type="checkbox" ${field.payment ? 'checked' : ''} oninput=${e => field.payment = e.target.checked} />
                                </label>
                                ${field.payment ? html`
                                    <label class="field">
                                        <div>Field Payment Amount</div>
                                        <input type="number" name="Field Payment Amount" min="0" placeholder="Field Payment Amount" value=${field.amount} oninput=${e => field.amount = e.target.valueAsNumber} required />
                                    </label>
                                ` : ((field.amount = 0) ? '' : '')}
                            ` : (((field.payment = false) && (field.amount = 0)) ? '' : '')}
                            ${this.form.payment && field.type === 'number' ? html`
                                <label class="field">
                                    <div>Field Payment Multiplier</div>
                                    <input type="checkbox" ${field.multiplier ? 'checked' : ''} oninput=${e => field.multiplier = e.target.checked}/>
                                </label>
                            ` : ((field.multiplier = false) ? '' : '')}
                            <div class="flex align-items-flex-end">
                                <button type="button" class="button button-border button-error margin-xxs" onclick=${() => this.removeField(section, index)}>
                                    <span class="material-symbols-outlined">delete</span>
                                    <span>Remove Field</span>
                                </button>
                            </div>
                        </div>

                        ${field.type === 'select' ? html`
                        <div class="margin-left-xl">
                            ${(field.options = field.options ?? []).map((option, index) => html`
                            <div>
                                <div class="flex flex-row flex-wrap">
                                    <label class="field">
                                        <div>Option Name</div>
                                        <input type="text" placeholder="Option Name" value=${option.name} oninput=${e => option.name = e.target.value} required/>
                                    </label>
                                    <label class="field">
                                        <div>Option Value</div>
                                        <input type="text" placeholder="Option Value" value=${option.value} oninput=${e => option.value = e.target.value} required/>
                                    </label>
                                    ${this.form.payment ? html`
                                        <label class="field">
                                            <div>Option Payment</div>
                                            <input type="checkbox" ${option.payment ? 'checked' : ''} oninput=${e => option.payment = e.target.checked} />
                                        </label>
                                        ${option.payment ? html`
                                            <label class="field">
                                                <div>Option Payment Amount</div>
                                                <input type="number" name="Option Payment Amount" min="0" placeholder="Option Payment Amount"
                                                    required
                                                    value=${option.amount}
                                                    oninput=${e => option.amount = e.target.valueAsNumber}
                                                />
                                            </label>
                                        ` : ((option.amount = 0) ? '' : '')}
                                    ` : (((option.payment = false) && (option.amount = 0)) ? '' : '')}
                                    <div class="flex align-items-flex-end">
                                        <button type="button" class="button button-border button-error margin-xxs" onclick=${() => field.options?.splice(index, 1)}>
                                            <span class="material-symbols-outlined">delete</span>
                                            <span>Remove Option</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            `)}
                        </div>
                        <div class="margin-left-xl">
                            <div class="flex flex-row">
                                <button type="button" class="button button-border button-success margin-xxs" onclick=${() => field.options?.push({ name: '', value: '', amount: 0, payment: false })}>
                                    <span class="material-symbols-outlined">add</span>
                                    <span>Add Option</span>
                                </button>
                            </div>
                        </div>
                        ` : ''}

                    </div>
                    `)}

                    <div class="flex flex-row">
                        <button type="button" class="button button-border button-success margin-xxs" onclick=${() => this.addField(section)}>
                            <span class="material-symbols-outlined">add</span>
                            <span>Add Field</span>
                        </button>
                    </div>
                </div>
                `)}

                <div class="flex flex-row">
                    <button type="button" class="button button-border button-success margin-xxs" onclick=${this.addSection}>
                        <span class="material-symbols-outlined">add</span>
                        <span>Add Section</span>
                    </button>
                </div>

                <div class="flex flex-row flex-wrap justify-content-flex-end">
                    <button type="submit" class="button button-primary margin-xxs">
                        <span class="material-symbols-outlined">save</span>
                        <span>${this.form.id ? 'Update' : 'Create'} Form</span>
                    </button>
                    ${this.form.id ? html`
                    <button type="button" class="button button-error margin-xxs" onclick=${this.formRemove}>
                        <span class="material-symbols-outlined">delete</span>
                        <span>Remove Form</span>
                    </button>
                    ` : ''}
                </div>
            </div>
        </form>

    </div>
    `

}
