import { component, html } from './deps';
import post from './post';

export default class Sponsor extends component {
    static tag = 'x-sponsor';

    async connected() {

    }

    render = () => html`

    <div class="container padding-m background-primary">
        <div class="margin-vertical-l">
            <h1 class="display-title color-white">Sponsor</h1>
            <hr class="divider background-white">
            <p class="lead lead-s margin-bottom-m color-white">Consider becoming a sponsor of Tucson Nurses Week Foundation.</p>
        </div>
    </div>

    <div class="container padding-m">
        <h1>Tucson Nurses Week Foundation Sponsorships 2024</h1>

        <p>Tucson Nurses' Week Foundation is a charitable organization that promotes and recognizes outstanding Nursing practices in the greater Tucson area. Everything we do is made possible by your generosity. We host, annually, an educational Conference and Healthy Fair as well as a Gala to celebrate our Tucson Nurses.</p>
        <p>Please consider becoming a sponsor of Tucson Nurses' Week! In addition to any donation of your preference, we have many tiers of sponsorship that we encourage you or your organization to consider. All sponsors are recognized on our site, on our social media channels, and at the yearly festivities to show our appreciation of their support.</p>
        <hr>

        <h3>Florence Nightingale - &#36;25,000</h3>
        <ul>
            <li>Recognition at the Gala (two minutes to address the audience)</li>
            <li>Two tables at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Two page full color ad in the program booklet (5”w X 8”h)</li>
            <li>Exhibit at the Healthy Fair (premium position)</li>
            <li>Logo/mentions in all promotional advertising in print and online</li>
            <li>Premier sponsorship ad position on the TNWF website (1920 x 500 px)</li>
            <li>Link between Foundation and Contributor websites</li>
            <li>Display of the TNWF “Proud Sponsor” logo on your website and business</li>
        </ul>

        <h3>Platinum - &#36;15,000</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>Two tables at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Full page full color ad in the program booklet (5”w X 8”h)</li>
            <li>Exhibit at the Healthy Fair (premium position)</li>
            <li>Logo/mentions in all promotional advertising in print and online</li>
            <li>Sidebar sponsorship ad position on the TNWF website (1000 x 200 px)</li>
            <li>Link between Foundation and Contributor websites</li>
            <li>Display of the TNWF “Proud Sponsor” logo on your website and business</li>
        </ul>

        <h3>Diamond - &#36;10,000</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>One table at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Full page full color ad in the program booklet (5”w X 8”h)</li>
            <li>Exhibit at the Healthy Fair (premium position)</li>
            <li>Logo/mentions in all promotional advertising in print and online</li>
            <li>Sidebar sponsorship ad position on the TNWF website (1000 x 200 px)</li>
            <li>Link between Foundation and Contributor websites</li>
            <li>Display of the TNWF “Proud Sponsor” logo on your website and business</li>
        </ul>

        <h3>Emerald - &#36;7,500</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>One table at the Gala</li>
            <li>Sponsor BOGO at the Gala</li>
            <li>Half page full color ad in the program booklet (5”w X 3.88”h)</li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Logo/mentions in all promotional advertising in print</li>
            <li>Recognition of sponsorship on the TNWF website</li>
            <li>Linked logo on TNWF homepage (200 x 200 px)</li>
            <li>Inclusion in sponsor list with link on the TNWF website</li>
            <li>Display of the TNWF “Proud Sponsor” logo on your website and business</li>
        </ul>

        <h3>Ruby - &#36;5,000</h3>
        <ul>
            <li>Recognition at the Gala</li>
            <li>Four tickets to the Gala</li>
            <li>Quarter page full color ad in the program booklet (2.38”w X 3.88”h)</li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Recognition of sponsorship on the TNWF website homepage (400 x 400 px 300 x 300 px)</li>
            <li>Logo/mentions in all promotional advertising in print</li>
            <li>Linked logo on homepage</li>
            <li>Inclusion in sponsor list with link on the TNWF website</li>
            <li>Display of the TNWF “Proud Sponsor” logo on your website and business</li>
        </ul>

        <h3>Turquoise - &#36;1,000</h3>
        <ul>
            <li>Eighth page full color ad in the program booklet (2.38”w X 1.76”h)</li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Mentions in all promotional advertising in print and online</li>
            <li>Linked logo on TNWF homepage (200 x 200 px)</li>
            <li>Inclusion in sponsor list with link on the TNWF website</li>
            <li>Display of the TNWF “Proud Sponsor” logo on your website and business</li>
        </ul>

        <h3>Friend of Nursing - &#36;500</h3>
        <ul>
            <li>Inclusion in sponsor list in the program booklet</li>
            <li>Exhibit at the Healthy Fair</li>
            <li>Mentions in all promotional advertising in print and online</li>
            <li>Inclusion in sponsor list with link on the TNWF website</li>
            <li>Display of the TNWF “Proud Sponsor” logo on your website and business</li>
        </ul>
        <p><strong>Mailing address:</strong> P.O. Box 65085, Tucson, Arizona 85728-5085</p>

        <p>In addition to these, we gladly accept donations of any amount. All monies go directly into paying for the events and supporting Tucson Nurses' Week Foundation. Fabulous 50 Alumni donations go directly to paying for student lunches at the Conference and Healthy Fair as well as the Clinical Nursing Research Grant.</p>
        <a class="button button-primary" href="/donate">Consider a Donation - Donate Here</a>

    </div>
    `

}